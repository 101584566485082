import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { Router } from "@angular/router";

@Component({
  selector: 'app-proses-penggajian2',
  templateUrl: './proses-penggajian2.component.html',
  styleUrls: ['./proses-penggajian2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProsesPenggajian2Component implements OnInit {

  items: MenuItem[];
  activeIndex: number = 0;
  link  = [   
    'rekapitulasi-perhitungan-absensi2',
    'rekapitulasi-ketidakhadiran2',
    'rekapitulasi-perhitungan-lembur2',
    'hitung-payroll2'
]

  constructor(private route: Router) { }
  ngOnInit() {
        let urlCurrent = this.route.url;
        for (let i = 0; i < this.link.length; i++) {
            if ( urlCurrent == '/' + this.link[i] ) {
                this.activeIndex = i;
                break;
            }
        }
      // this.activeIndex = parseInt(localStorage.getItem('proses-penggajian'));
      this.items = [
      {
          label: 'Rekap Absensi',
          command: (event: any) => {
              this.activeIndex = 0;
              this.route.navigate(['rekapitulasi-perhitungan-absensi2']);
              localStorage.setItem('proses-penggajian', String(this.activeIndex));
          }
      },
      {
          label: 'Ketidakhadiran',
          command: (event: any) => {
              this.activeIndex = 1;
              this.route.navigate(['rekapitulasi-ketidakhadiran2']);
              localStorage.setItem('proses-penggajian', String(this.activeIndex));
          }
      },
      {
          label: 'Hitung Lembur',
          command: (event: any) => {
              this.activeIndex = 2;
              this.route.navigate(['rekapitulasi-perhitungan-lembur2']);
              localStorage.setItem('proses-penggajian', String(this.activeIndex));
          }
      },
      {
          label: 'Payroll',
          command: (event: any) => {
              this.activeIndex = 3;
              this.route.navigate(['hitung-payroll2']);
              localStorage.setItem('proses-penggajian', String(this.activeIndex));
          }
      }

      ];

  }

}
