export const routeRegistrasi = [
    { path: 'daftar-monitoring-database-pegawai', loadChildren: () => import('./page-registrasi.module').then(m => m.DaftarMonitoringDatabasePegawaiModule) },
    { path: 'daftar-status-pegawai', loadChildren: () => import('./page-registrasi.module').then(m => m.DaftarStatusPegawaiModule) },
    { path: 'registrasi-pegawai', loadChildren: () => import('./page-registrasi.module').then(m => m.DataPegawaiModule) },
    { path: 'alamat', loadChildren: () => import('./page-registrasi.module').then(m => m.AlamatModule) },
    { path: 'tab-riwayat', loadChildren: () => import('./page-registrasi.module').then(m => m.TabRiwayatModule) },
    { path: 'rekening-bank', loadChildren: () => import('./page-registrasi.module').then(m => m.RekeningBankModule) },
    { path: 'fasilitas-perusahaan', loadChildren: () => import('./page-registrasi.module').then(m => m.FasilitasPerusahaanModule) },
    { path: 'keahlian-bahasa', loadChildren: () => import('./page-registrasi.module').then(m => m.KeahlianBahasaModule) },
    { path: 'keluarga', loadChildren: () => import('./page-registrasi.module').then(m => m.KeluargaModule) },
    { path: 'upload-dokumen', loadChildren: () => import('./page-registrasi.module').then(m => m.UploadDokumenModule) },
    { path: 'registrasi-komponen-gaji', loadChildren: () => import('./page-registrasi.module').then(m => m.RegistrasiKomponenGajiModule) },
    { path: 'daftar-pegawai-meninggal', loadChildren: () => import('./page-registrasi.module').then(m => m.DaftarPegawaiMeninggalModule) },
    { path: 'daftar-pemeriksaan-pegawai', loadChildren: () => import('./page-registrasi.module').then(m => m.DaftarPemeriksaanPegawaiModule) },
    { path: 'pemberian-fasilitas-kerja', loadChildren: () => import('./page-registrasi.module').then(m => m.PemberianFasilitasKerjaModule) },
    { path: 'referensi-pegawai', loadChildren: () => import('./page-registrasi.module').then(m => m.ReferensiPegawaiModule) },
    { path: 'data-asuransi', loadChildren: () => import('./page-registrasi.module').then(m => m.DataAsuransiModule) },
    { path: 'pegawai-histori-meninggal', loadChildren: () => import('./page-registrasi.module').then(m => m.PegawaiHistoriMeninggalModule) },
    { path: 'data-asuransi2', loadChildren: () => import('./page-registrasi.module').then(m => m.DataAsuransi2Module) },
    { path: 'daftar-blacklist-pegawai', loadChildren: () => import('./page-registrasi.module').then(m => m.DaftarBlacklistPegawaiModule) },
    { path: 'input-pemeriksaan-pegawai', loadChildren: () => import('./page-registrasi.module').then(m => m.InputPemeriksaanPegawaiModule) },
    { path: 'pemeriksaan-pegawai', loadChildren: () => import('./page-registrasi.module').then(m => m.PemeriksaanPegawaiModule) },
    { path: 'emergency-contact', loadChildren: () => import('./page-registrasi.module').then(m => m.EmergencyContactModule) },
    { path: 'miniprofiledashboard', loadChildren: () => import('./page-registrasi.module').then(m => m.MiniprofiledashboardModule) },
    { path: 'infopegawaiview', loadChildren: () => import('./page-registrasi.module').then(m => m.InfopegawaiviewModule) },
    { path: 'daftar-terima-editdatapegawaiviamobile', loadChildren: () => import('./page-registrasi.module').then(m => m.DaftarTerimaEditdatapegawaiviamobileModule) },
    { path: 'daftar-monitoring-status-pengajuan', loadChildren: () => import('./page-registrasi.module').then(m => m.DaftarMonitoringStatusPengajuanModule) },
    { path: 'upload-ptkp-pegawai-v2', loadChildren: () => import('./page-registrasi.module').then(m => m.UploadPtkpPegawaiModule) },
    { path: 'upload-ptkp-pegawai', loadChildren: () => import('./page-registrasi.module').then(m => m.UploadPtkpPegawaiSingleModule) },
    { path: 'ubah-ptkp-pegawai', loadChildren: () => import('./page-registrasi.module').then(m => m.UbahPtkpPegawaiModule) },
    { path: 'import-data-pegawai-excel-v2', loadChildren: () => import('./page-registrasi.module').then(m => m.ImportDataPegawaiExcelModule) },
    { path: 'import-data-pegawai-simple-excel-v2', loadChildren: () => import('./page-registrasi.module').then(m => m.ImportDataPegawaiSimpleExcelModule) },
    { path: 'import-data-pegawai-excel', loadChildren: () => import('./page-registrasi.module').then(m => m.ImportDataPegawaiExcelSingleModule) },
    { path: 'import-data-pegawai-simple-excel', loadChildren: () => import('./page-registrasi.module').then(m => m.ImportDataPegawaiSimpleExcelSingleModule) },
    { path: 'pemberian-fasilitas-kerja-new', loadChildren: () => import('./page-registrasi.module').then(m => m.PemberianFasilitasKerjaNewModule) },

    { path: 'log-perubahan-rekap-absen', loadChildren: () => import('./page-registrasi.module').then(m => m.LogPerubahanRekapAbsenModule) },

    { path: 'master-data/quiz-pegawai', loadChildren: () => import('./page-registrasi.module').then(m => m.QuizPegawaiModule) },
    { path: 'master-data/quiz-pertanyaan', loadChildren: () => import('./page-registrasi.module').then(m => m.QuizPertanyaanModule) },
    { path: 'master-data/quiz-mapping-jawaban', loadChildren: () => import('./page-registrasi.module').then(m => m.QuizMappingJawabanModule) },
    // { path: 'master-data/quiz-daftar-monitoring', loadChildren: () => import('./page-registrasi.module').then(m => m.QuizDaftarMonitoringModule) },

];

// saya tutup, sekarang pake system module

// import { AuthGuard } from '../../global';
// import * as pRegistrasiPegawai from './';

// export const pathRegistrasiPegawai = [
//     { canActivate: [AuthGuard], path: 'registrasi-pegawai', component: pRegistrasiPegawai.DataPegawaiComponent },
//     { canActivate: [AuthGuard], path: 'alamat', component: pRegistrasiPegawai.AlamatComponent },
//     { canActivate: [AuthGuard], path: 'tab-riwayat', component: pRegistrasiPegawai.TabRiwayatComponent },
//     { canActivate: [AuthGuard], path: 'rekening-bank', component: pRegistrasiPegawai.RekeningBankComponent },
//     { canActivate: [AuthGuard], path: 'fasilitas-perusahaan', component: pRegistrasiPegawai.FasilitasPerusahaanComponent },
//     { canActivate: [AuthGuard], path: 'keahlian-bahasa', component: pRegistrasiPegawai.KeahlianBahasaComponent },
//     { canActivate: [AuthGuard], path: 'keluarga', component: pRegistrasiPegawai.KeluargaComponent },
//     { canActivate: [AuthGuard], path: 'upload-dokumen', component: pRegistrasiPegawai.UploadDokumenComponent },
//     { canActivate: [AuthGuard], path: 'registrasi-komponen-gaji', component: 	pRegistrasiPegawai.RegistrasiKomponenGajiComponent, },        
//     { canActivate: [AuthGuard], path: 'daftar-monitoring-database-pegawai', component: 	pRegistrasiPegawai.DaftarMonitoringDatabasePegawaiComponent, },        
//     { canActivate: [AuthGuard], path: 'daftar-pegawai-meninggal', component: 	pRegistrasiPegawai.DaftarPegawaiMeninggalComponent, }, 
//     { canActivate: [AuthGuard], path: 'daftar-pemeriksaan-pegawai', component: 	pRegistrasiPegawai.DaftarPemeriksaanPegawaiComponent, },
//     { canActivate: [AuthGuard], path: 'pemberian-fasilitas-kerja', component: pRegistrasiPegawai.PemberianFasilitasKerjaComponent },
//     { canActivate: [AuthGuard], path: 'proses-registrasi-pegawai', component: pRegistrasiPegawai.ProsesRegistrasiPegawaiComponent},
//     { canActivate: [AuthGuard], path: 'referensi-pegawai', component: pRegistrasiPegawai.ReferensiPegawaiComponent },
//     { canActivate: [AuthGuard], path: 'data-asuransi', component: pRegistrasiPegawai.DataAsuransiComponent },
//     { canActivate: [AuthGuard], path: 'pegawai-histori-meninggal', component: pRegistrasiPegawai.PegawaiHistoriMeninggalComponent },
//     { canActivate: [AuthGuard], path: 'data-asuransi2', component: pRegistrasiPegawai.DataAsuransi2Component },
//     { canActivate: [AuthGuard], path: 'daftar-blacklist-pegawai', component: pRegistrasiPegawai.DaftarBlacklistPegawaiComponent },
//     { canActivate: [AuthGuard], path: 'input-pemeriksaan-pegawai', component: pRegistrasiPegawai.InputPemeriksaanPegawaiComponent },
//     { canActivate: [AuthGuard], path: 'pemeriksaan-pegawai', component: pRegistrasiPegawai.PemeriksaanPegawaiComponent },
//     { canActivate: [AuthGuard], path: 'emergency-contact', component: pRegistrasiPegawai.EmergencyContactComponent},
//     { canActivate: [AuthGuard], path: 'miniprofiledashboard', component: pRegistrasiPegawai.MiniprofiledashboardComponent},
//     { canActivate: [AuthGuard], path: 'infopegawaiview', component: pRegistrasiPegawai.InfopegawaiviewComponent},
//     // { canActivate: [AuthGuard], path: 'daftar-terima-editdatapegawaiviamobile', component: pRegistrasiPegawai.DaftarTerimaEditdatapegawaiviamobileComponent},

// ];
