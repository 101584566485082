export const routeMasterMap = [
    { path: 'map-kelompoktransaksi-to-keteranganalasan-award-offence', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiKeteranganalasanModule) },
    { path: 'map-kelompoktransaksi-to-posting-award-offence', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiPostingModule) },
    { path: 'map-kelompoktransaksi-to-strukturnomor-award-offence', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiStrukturnomorModule) },
    { path: 'map-kelompoktransaksi-to-jenisdokumen-award-offence', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiJenisdokumenModule) },
    { path: 'map-kelompoktransaksi-to-keteranganalasan-orientasi', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiKeteranganalasanModule) },
    { path: 'map-kelompoktransaksi-to-posting-orientasi', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiPostingModule) },
    { path: 'map-kelompoktransaksi-to-strukturnomor-orientasi', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiStrukturnomorModule) },
    { path: 'map-kelompoktransaksi-to-keteranganalasan-perjalanan-dinas', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiKeteranganalasanModule) },
    { path: 'map-kelompoktransaksi-to-posting-perjalanan-dinas', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiPostingModule) },
    { path: 'map-kelompoktransaksi-to-strukturnomor-perjalanan-dinas', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiStrukturnomorModule) },
    { path: 'map-kelompoktransaksi-to-jenisdokumen-perjalanan-dinas', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiJenisdokumenModule) },
    { path: 'map-kelompoktransaksi-to-keteranganalasan-cuti', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiKeteranganalasanModule) },
    { path: 'map-kelompoktransaksi-to-posting-cuti', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiPostingModule) },
    { path: 'map-kelompoktransaksi-to-strukturnomor-cuti', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiStrukturnomorModule) },
    { path: 'map-kelompoktransaksi-to-jenisdokumen-cuti', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiJenisdokumenModule) },
    { path: 'map-kelompoktransaksi-to-keteranganalasan-lembur', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiKeteranganalasanModule) },
    { path: 'map-kelompoktransaksi-to-posting-lembur', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiPostingModule) },
    { path: 'map-kelompoktransaksi-to-strukturnomor-lembur', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiStrukturnomorModule) },
    { path: 'map-kelompoktransaksi-to-jenisdokumen-lembur', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiJenisdokumenModule) },
    { path: 'map-kelompoktransaksi-to-keteranganalasan-resign', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiKeteranganalasanModule) },
    { path: 'map-kelompoktransaksi-to-posting-resign', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiPostingModule) },
    { path: 'map-kelompoktransaksi-to-strukturnomor-resign', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiStrukturnomorModule) },
    { path: 'map-kelompoktransaksi-to-jenisdokumen-resign', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiJenisdokumenModule) },
    { path: 'map-kelompoktransaksi-to-keteranganalasan-pinjaman', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiKeteranganalasanModule) },
    { path: 'map-kelompoktransaksi-to-posting-pinjaman', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiPostingModule) },
    { path: 'map-kelompoktransaksi-to-strukturnomor-pinjaman', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiStrukturnomorModule) },
    { path: 'map-kelompoktransaksi-to-jenisdokumen-pinjaman', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiJenisdokumenModule) },
    { path: 'map-kelompoktransaksi-to-keteranganalasan-reimburs', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiKeteranganalasanModule) },
    { path: 'map-kelompoktransaksi-to-posting-reimburs', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiPostingModule) },
    { path: 'map-kelompoktransaksi-to-strukturnomor-reimburs', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiStrukturnomorModule) },
    { path: 'map-kelompoktransaksi-to-jenisdokumen-reimburs', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiJenisdokumenModule) },
    { path: 'map-kelompoktransaksi-to-keteranganalasan-reimburs-perjalanan-dinas', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiKeteranganalasanModule) },
    { path: 'map-kelompoktransaksi-to-posting-reimburs-perjalanan-dinas', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiPostingModule) },
    { path: 'map-kelompoktransaksi-to-strukturnomor-reimburs-perjalanan-dinas', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiStrukturnomorModule) },
    { path: 'map-kelompoktransaksi-to-jenisdokumen-reimburs-perjalanan-dinas', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiJenisdokumenModule) },
    { path: 'map-kelompoktransaksi-to-keteranganalasan-evaluasi', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiKeteranganalasanModule) },
    { path: 'map-kelompoktransaksi-to-posting-evaluasi', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiPostingModule) },
    { path: 'map-kelompoktransaksi-to-strukturnomor-evaluasi', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiStrukturnomorModule) },
    { path: 'map-kelompoktransaksi-to-jenisdokumen-evaluasi', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiJenisdokumenModule) },
    { path: 'map-kelompoktransaksi-to-keteranganalasan-phk', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiKeteranganalasanModule) },
    { path: 'map-kelompoktransaksi-to-posting-phk', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiPostingModule) },
    { path: 'map-kelompoktransaksi-to-strukturnomor-phk', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiStrukturnomorModule) },
    { path: 'map-kelompoktransaksi-to-jenisdokumen-phk', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiJenisdokumenModule) },
    { path: 'map-kelompoktransaksi-to-keteranganalasan-pensiun', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiKeteranganalasanModule) },
    { path: 'map-kelompoktransaksi-to-posting-pensiun', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiPostingModule) },
    { path: 'map-kelompoktransaksi-to-strukturnomor-pensiun', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompoktransaksiStrukturnomorModule) },

    { path: 'map-kelompokevaluasi-to-jabatan-evaluasi', loadChildren : () => import('./master-evaluasi-pendidikan.module').then(m => m.MapKelompokevaluasiJabatanModule) },
    { path: 'map-kelompokevaluasi-to-jabatan-orientasi', loadChildren : () => import('./master-evaluasi-pendidikan.module').then(m => m.MapKelompokevaluasiJabatanModule) },

    { path: 'map-kelompok-shift-to-shift-jadwal', loadChildren : () => import('./master-jadwal.module').then(m => m.MapKelompokShiftToShiftModule) },
    { path: 'map-event-to-produk-orientasi', loadChildren : () => import('./master-produk.module').then(m => m.MapEventToProdukModule) },
    { path: 'map-golonganpegawai-to-pangkat-pegawai', loadChildren : () => import('./master-pegawai.module').then(m => m.MapGolonganpegawaiPangkatModule) },
    { path: 'map-angka-to-bulan-sisadmin', loadChildren : () => import('./master-kalender.module').then(m => m.MapAngkaToBulanModule) },
    { path: 'map-objek-modul-to-kelompok-user-sisadmin', loadChildren : () => import('./master-aplikasi.module').then(m => m.MapObjekModulToKelompokUserModule) },
    { path: 'master-map-objek-modul-to-modul-aplikasi-sisadmin', loadChildren : () => import('./master-aplikasi.module').then(m => m.MapObjekModulToModulAplikasiModule) },
 
    { path: 'map-kelompok-user-jabatan', loadChildren : () => import('./master-pegawai.module').then(m => m.MapKelompokUserJabatanModule) },
    { path: 'map-kelompok-user-golonganpegawai', loadChildren : () => import('./master-pegawai.module').then(m => m.MapKelompokUserGolonganPegawaiModule) },
                    
    { path: 'profile-history-strategy-map', loadChildren : () => import('./master-kpi.module').then(m => m.ProfileHistoryStrategyMapModule) },
    { path: 'profile-history-strategy-map-department', loadChildren : () => import('./master-kpi.module').then(m => m.ProfileHistoryStrategyMapDepartmentModule) },
    { path: 'map-kelompok-status-to-status', loadChildren : () => import('./master-kelompok-transaksi.module').then(m => m.MapKelompokStatusToStatusModule) },
    { path: 'map-komponen-to-hasil-pemeriksaan-pegawai', loadChildren : () => import('./master-informasi.module').then(m => m.MapKomponenHasilModule) },
    { path: 'map-modul-aplikasi-to-objek-modul-aplikasi', loadChildren : () => import('./master-aplikasi.module').then(m => m.MapModulAplikasiToObjekModulAplikasiModule) }
];


