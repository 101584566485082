//import * as redirect from './redirect.module';
export { RedirectProsesRegistrasiPegawaiComponent } from './redirect-proses-registrasi-pegawai/redirect-proses-registrasi-pegawai.component';
export { RedirectProsesPenggajianComponent  } from './redirect-proses-penggajian/redirect-proses-penggajian.component';
export { RedirectMenuResignComponent  } from './redirect-menu-resign/redirect-menu-resign.component';
export { RedirectMenuUploadComponent  } from './redirect-menu-upload/redirect-menu-upload.component';
export { RedirectMenuEvaluasiJabatanComponent } from './redirect-menu-evaluasi-jabatan/redirect-menu-evaluasi-jabatan.component';
export { RedirectMenuRewardComponent  } from './redirect-menu-reward/redirect-menu-reward.component';
export { RedirectMenuSanksiComponent  } from './redirect-menu-sanksi/redirect-menu-sanksi.component';
export { RedirectMenuCutiComponent  } from './redirect-menu-cuti/redirect-menu-cuti.component';
export { RedirectMenuLemburComponent  } from './redirect-menu-lembur/redirect-menu-lembur.component';
export { RedirectPembatalanTransaksiComponent  } from './redirect-pembatalan-transaksi/redirect-pembatalan-transaksi.component';
export { RedirectMenuPhkComponent  } from './redirect-menu-phk/redirect-menu-phk.component';
export { RedirectMenuPensiunComponent  } from './redirect-menu-pensiun/redirect-menu-pensiun.component';
export { RedirectMenuReimbursPerjalananDinasComponent } from './redirect-menu-reimburs-perjalanan-dinas/redirect-menu-reimburs-perjalanan-dinas.component';
export { RedirectMenuPerjalananDinasComponent } from './redirect-menu-perjalanan-dinas/redirect-menu-perjalanan-dinas.component';
export { RedirectMenuPerubahanJadwalKerjaComponent } from './redirect-menu-perubahan-jadwal-kerja/redirect-menu-perubahan-jadwal-kerja.component';
export { RedirectMenuPerubahanPinjamanComponent  } from './redirect-menu-perubahan-pinjaman/redirect-menu-perubahan-pinjaman.component';
export { RedirectMenuPinjamanComponent  } from './redirect-menu-pinjaman/redirect-menu-pinjaman.component';
export { RedirectMenuReimbursComponent  } from './redirect-menu-reimburs/redirect-menu-reimburs.component';
export { RedirectMenuOrientasiPegawaiComponent  } from './redirect-menu-orientasi-pegawai/redirect-menu-orientasi-pegawai.component';
export { RedirectMenuKesejahteraanComponent  } from './redirect-menu-kesejahteraan/redirect-menu-kesejahteraan.component';

import * as steps from './step.module';

import * as redirect from './';

export const StepsModule = [
	steps.ProsesRegistrasiPegawaiModule,
	steps.ProsesPenggajianModule,
	steps.ProsesPenggajian2Module,
	steps.ResignMenuModule,
	steps.MenuUploadModule,
	steps.MenuEvaluasiJabatanModule,
	steps.MenuRewardModule,
	steps.MenuSanksiModule,
	steps.MenuCutiModule,
	steps.MenuLemburModule,
	steps.MenuPembatalanTransaksiModule,
	steps.MenuPhkModule,
	steps.MenuPensiunModule,
	steps.MenuReimbursPerjalananDinasModule,
	steps.MenuPerjalananDinasModule,
	steps.MenuPerubahanJadwalKerjaModule,
	steps.MenuPerubahanPinjamanModule,
	steps.MenuPinjamanModule,
	steps.MenuReimbursModule,
	steps.MenuOrientasiPegawaiModule,
	steps.MenuKesejahteraanModule

]; 

export const pageRedirect = [
	redirect.RedirectProsesRegistrasiPegawaiComponent,
	redirect.RedirectProsesPenggajianComponent,
	redirect.RedirectMenuResignComponent,
	redirect.RedirectMenuUploadComponent,
	redirect.RedirectMenuEvaluasiJabatanComponent,
	redirect.RedirectMenuRewardComponent,
	redirect.RedirectMenuSanksiComponent,
	redirect.RedirectMenuCutiComponent,
	redirect.RedirectMenuLemburComponent,
	redirect.RedirectPembatalanTransaksiComponent,
	redirect.RedirectMenuPhkComponent,
	redirect.RedirectMenuPensiunComponent,
	redirect.RedirectMenuReimbursPerjalananDinasComponent,
	redirect.RedirectMenuPerjalananDinasComponent,
	redirect.RedirectMenuPerubahanJadwalKerjaComponent,
	redirect.RedirectMenuPerubahanPinjamanComponent,
	redirect.RedirectMenuPinjamanComponent,
	redirect.RedirectMenuReimbursComponent,
	redirect.RedirectMenuOrientasiPegawaiComponent,
	redirect.RedirectMenuKesejahteraanComponent
]; 

// export const RedirectModule = [
// 	redirect.RedirectProsesRegistrasiPegawaiModule,
// 	redirect.RedirectProsesPenggajianModule,
// ]; 