import { LocaleSettings } from 'primeng/primeng';

import vlocal from '../local-config'

export class Configuration {

    public static lang : string = 'id';

    static get() {

        if (window.location.hostname.indexOf('115.85') > -1){
            return v115;
        } else if (window.location.hostname.indexOf('hris.rsgrandmed.com') > -1){
            return vBdg2grandmed;        
        } else if (window.location.hostname.indexOf('bdg2.jasamedika.com') > -1){
            return vBdg2Jasmed;        
        } else if (window.location.hostname.indexOf('jasamedika') > -1){
            return vJasmed;
        } else if (window.location.hostname.indexOf('bottis.co.id') > -1){
            return vBottis;
        } else if (window.location.hostname.indexOf('bottis.id') > -1){
            return vBottisId;
        } else if (window.location.hostname.indexOf('.194') > -1){
            return v194;
        // } else if (window.location.hostname.indexOf('.33') > -1){
        //     return v33;
        } else if (window.location.hostname.indexOf('172.2.103.4') > -1) {
            return vLocalGrandmed;
        } else if (window.location.hostname.indexOf('.177') > -1) {
            return v177;
        } else if (window.location.hostname.indexOf('.22') > -1){
            return v22;
        } else if (window.location.hostname.indexOf('.161') > -1){
            return v161;
        } else if (window.location.hostname.indexOf('127.') > -1 || window.location.hostname.indexOf('localhost') > -1){
            return vlocal;
        } else {
            return vlocal;
        }    
    }


    public static localeCal(){
        let  _locale: LocaleSettings;

        let en = {
            firstDayOfWeek: 0,
            dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            dayNamesMin: ["Su","Mo","Tu","We","Th","Fr","Sa"],
            monthNames: [ "January","February","March","April","May","June","July","August","September","October","November","December" ],
            monthNamesShort: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
            today: 'Today',
            clear: 'Clear',
            dateFormat: 'mm/dd/yy',
            weekHeader: 'Wk'
        };

        let id =  {
            firstDayOfWeek: 0,
            dayNames: ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"],
            dayNamesShort: ["Mng", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
            dayNamesMin: ["Mg","Sn","Sl","Rb","Km","Jm","Sb"],
            monthNames: [ "Januari","Februari","Maret","April","Mei","Juni","Juli","Agustus","September","Oktober","November","Desember" ],
            monthNamesShort: [ "Jan", "Feb", "Mar", "Apr", "Mei", "Jun","Jul", "Agu", "Sep", "Okt", "Nov", "Des" ],
            today: 'Hari ini',
            clear: 'Bersihkan',
            dateFormat: 'dd/mm/yy',
            weekHeader: 'Mg'	
        };

        switch (Configuration.lang) {
            case 'en':
                _locale = en;
                break;
            case 'id':
                _locale = id;
                break;
            default:
                _locale = id;
                break;
        }


        return _locale;

    }

    static kodeKelompokTransaksi() {

        return {
            Orientasi               :'2',
            PerubahanJadwalKerja    :'4',
            PengajuanPerjalananDinas:'5',
            CutiPegawai             :'11',
            Resign                  :'12',
            Reimburs                :'13',
            Lembur                  :'15',
            PengajuanReward         :'18',
            PengajuanSanksi         :'19',
            PengajuanPinjaman       :'20',
            MutasiPegawai           :'28',
            Phk                     :'82',
            HoldPinjaman            :'91',
            PembatalanCuti          :'93',
            PembatalanDinas         :'94',//113
            PembatalanLembur        :'95',
            Pensiun                 :'92',//105
            Bsc_kpi                 :'101',
            PemberianKesejahteraan  :'103',
            Pengajuan_Realisasi_KPI :'104',
            
        }
    }

    public static warnaChart = [
            '#e6194b',
            '#3cb44b',
            '#ffe119',
            '#0082c8',
            '#f58231',
            '#911eb4',
            '#46f0f0',
            '#f032e6',
            '#d2f53c',
            '#fabebe',
            '#008080',
            '#e6beff',
            '#aa6e28',
            '#fffac8',
            '#800000',
            '#aaffc3',
            '#808000',
            '#ffd8b1',
            '#000080',
            '#808080',
            '#000000',
    ];
}



class AppStaticMenu {
    public static model = [

    {
       "label": "Dashboard",
       "icon": "fa fa-fw fa-chevron-right",
       "routerLink": ["/"] 
    },
    {
        "label": "Objek Modul Aplikasi",
        "icon": "fa fa-fw fa-chevron-right",
        "routerLink": ["/master-objek-modul-aplikasi-sisadmin"] 
     },    
     {
         "label": "Daftar Profile",
         "icon": "fa fa-fw fa-chevron-right",
         "routerLink": ["/daftar-profile"] 
      },    
      {
          "label": "Modul Aplikasi Per-Profile",
          "icon": "fa fa-fw fa-chevron-right",
          "routerLink": ["/modul-aplikasi-profile"] 
       }
];

}

var vLocalGrandmed = {
    apiBackend          : '',
    dataMaster          : 'https://172.2.103.4/dataMaster',
    dataMasterNew       : 'https://172.2.103.4/dataMaster',
  
    report              : 'https://172.2.103.4/authInfo',
    smartReport         : 'https:/172.2.103.4/smartReport',
    authLogin           : 'https://172.2.103.4/authInfo',
  
    dataHr1Mod1         : 'https://172.2.103.4/dataHr1Mod1',
    dataHr1Mod2         : 'https://172.2.103.4/dataHr1Mod1',
    dataHr2Mod1         : 'https://172.2.103.4/dataHr1Mod1',
    dataHr2Mod2         : 'https://172.2.103.4/dataHr1Mod1',
  
    dataHr2Mod3         : 'https://172.2.103.4/dataHr2Mod3',
    dataBSC             : 'https://172.2.103.4/dataBSC',
  
    dataBridging        : 'https://172.2.103.4/dataBridging',
  
    // dataBNI          : 'http://192.168.0.212:9933',
  
    
    page                : 1,
    rows                : 10,
    headerToken         : 'x-auth-token',
    menuDinamic         : true,
    idleTimeOut         : 1440, // menit
    menuStatic          :  [{
            "label": "Dashboard",
            "icon": "fa fa-fw fa-chevron-right",
            "routerLink": ["/"] 
        }],
    /// TIGA INI JGN PERNAH DIUBAH OKE.. HATURNUHUN    
    resourceFile        : 'https://172.2.103.4/authInfo',
    resourceReport      : 'https:/172.2.103.4/authInfo',
    socketIO            : 'https://172.2.103.4'
  }

var v194 = {
    apiBackend          : '',
    dataMaster          : /*'http://'+ window.location.hostname + ':8292', //*/'https://hris.bottis.co.id/dataMaster',
    dataMasterNew       : /*'http://'+ window.location.hostname + ':8292', //*/'https://hris.bottis.co.id/dataMaster',
  
    report              : /*'http://'+ window.location.hostname + ':8797', //*/'https://hris.bottis.co.id/authInfo',
    smartReport         : /*'http://'+ window.location.hostname + ':8797', //*/'https://hris.bottis.co.id/smartReport',
    authLogin           : /*'http://'+ window.location.hostname + ':8797', //*/'https://hris.bottis.co.id/authInfo',
  
    dataHr1Mod1         : /*'http://'+ window.location.hostname + ':8393', //*/'https://hris.bottis.co.id/dataHr1Mod1',
    dataHr1Mod2         : /*'http://'+ window.location.hostname + ':8393', //*/'https://hris.bottis.co.id/dataHr1Mod1',
    dataHr2Mod1         : /*'http://'+ window.location.hostname + ':8393', //*/'https://hris.bottis.co.id/dataHr1Mod1',
    dataHr2Mod2         : /*'http://'+ window.location.hostname + ':8393', //*/'https://hris.bottis.co.id/dataHr1Mod1',
  
    dataHr2Mod3         : 'http://192.168.0.194:9696', //*/'https://hris.bottis.co.id/dataHr2Mod3',
    dataBSC             : /*'http://192.168.0.143:9898', //*/'https://hris.bottis.co.id/dataBSC',
  
    dataBridging        : /*'http://192.168.0.143:9797', //*/ 'https://hris.bottis.co.id/dataBridging',
  
    // dataBNI          : 'http://192.168.0.212:9933',
  
    
    page                : 1,
    rows                : 10,
    headerToken         : 'x-auth-token',
    menuDinamic         : true,
    idleTimeOut         : 1440, // menit
    menuStatic          :  [{
            "label": "Dashboard",
            "icon": "fa fa-fw fa-chevron-right",
            "routerLink": ["/"] 
        }],
    /// TIGA INI JGN PERNAH DIUBAH OKE.. HATURNUHUN    
    resourceFile        : /*'http://'+ window.location.hostname + ':8797', //*/'https://hris.rsgrandmed.com/authInfo',
    resourceReport      : /*'http://'+ window.location.hostname + ':8797', //*/'https://hris.rsgrandmed.com/authInfo',
    socketIO            : /*'http://192.168.0.143:2222'  //*/'https://hris.bottis.co.id'
  }


// var v194 = {
            
//     apiBackend          : '',

//     dataMaster          : 'https://192.168.0.194/dataMaster',
//     dataMasterNew       : 'https://192.168.0.194/dataMaster',

//     resourceFile        : 'https://192.168.0.194/authInfo',
//     report              : 'https://192.168.0.194/authInfo',
//     smartReport         : 'https://192.168.0.194/smartReport',
//     authLogin           : 'https://192.168.0.194/authInfo',

//     dataHr1Mod1         : 'https://192.168.0.194/dataHr1Mod1',          
//     dataHr1Mod2         : 'https://192.168.0.194/dataHr1Mod1',          
//     dataHr2Mod1         : 'https://192.168.0.194/dataHr1Mod1',
//     dataHr2Mod2         : 'https://192.168.0.194/dataHr1Mod1',

//     dataHr2Mod3         : 'https://192.168.0.194/dataHr2Mod3',

//     dataBSC             : 'https://192.168.0.194/dataBSC',   

//     dataBridging        : 'https://192.168.0.194/authInfo',

//     resourceReport      : 'https://192.168.0.194/authInfo', 
//     page                : 1,
//     rows                : 15,
//     headerToken         : 'x-auth-token',
//     menuDinamic         : true,
//     idleTimeOut         : 480, // menit
//     menuStatic          : AppStaticMenu.model,
//     socketIO            : 'https://192.168.0.194:2222'
// };

var v22 = {
    apiBackend          : '',

    dataMaster          : 'http://192.168.0.22:8292', //*/'https://hris.bottis.co.id/dataMaster',
    dataMasterNew       : 'http://192.168.0.22:8292', //*/'https://hris.bottis.co.id/dataMaster',

    report              : 'http://192.168.0.22:8797', //*/'https://hris.bottis.co.id/authInfo',
    smartReport         : 'http://192.168.0.22:6797', //*/'https://hris.bottis.co.id/smartReport',
    authLogin           : /*'http://192.168.0.22:9797', //*/'https://hris.bottis.co.id/authInfo',

    dataHr1Mod1         : 'http://192.168.0.22:8393', //*/'https://hris.bottis.co.id/dataHr1Mod1',
    dataHr1Mod2         : 'http://192.168.0.22:8393', //*/'https://hris.bottis.co.id/dataHr1Mod1',
    dataHr2Mod1         : 'http://192.168.0.22:8393', //*/'https://hris.bottis.co.id/dataHr1Mod1',
    dataHr2Mod2         : 'http://192.168.0.22:8393', //*/'https://hris.bottis.co.id/dataHr1Mod1',

    dataHr2Mod3         : 'http://192.168.0.22:9696',  //*/'https://hris.bottis.co.id/dataHr2Mod3',
    
    dataBSC             : /*'http://192.168.0.194:9898', //*/'https://hris.bottis.co.id/dataBSC',

    dataBridging        : /*'http://192.168.0.194:9797', //*/ 'https://hris.bottis.co.id/authInfo',
    
    page                : 1,
    rows                : 10,
    headerToken         : 'x-auth-token',
    menuDinamic         : true,
    idleTimeOut         : 1440, // menit
    menuStatic          :  [{
           "label": "Dashboard",
           "icon": "fa fa-fw fa-chevron-right",
           "routerLink": ["/"] 
        }],
    /// TIGA INI JGN PERNAH DIUBAH OKE.. HATURNUHUN    
    resourceFile        : /*'http://192.168.0.22:9797', //*/'https://hris.bottis.co.id/authInfo',
    resourceReport      : /*'http://192.168.0.22:9797', //*/'https://hris.bottis.co.id/authInfo',
    socketIO            : /*'http://192.168.0.194:2222'  //*/'https://hris.bottis.co.id:2222'
};

var v33 = {
    apiBackend          : '',

    dataMaster          : 'http://192.168.0.33:9292',
    dataMasterNew       : 'http://192.168.0.33:9292',

    resourceFile        : 'http://192.168.0.33:9797',
    report              : 'http://192.168.0.33:9797',
    smartReport         : 'http://192.168.0.33:6797',
    authLogin           : 'http://192.168.0.33:9797',

    dataHr1Mod1         : 'http://192.168.0.33:9393',
    dataHr1Mod2         : 'http://192.168.0.33:9393',
    dataHr2Mod1         : 'http://192.168.0.33:9393',
    dataHr2Mod2         : 'http://192.168.0.33:9393',

    dataHr2Mod3         : 'http://192.168.0.33:9696',

    dataBSC             : 'http://192.168.0.33:9898',

    dataBridging        : 'http://192.168.0.33:9193',

    resourceReport      : 'http://192.168.0.33:9797',
    page                : 1,
    rows                : 15,
    headerToken         : 'x-auth-token',
    menuDinamic         : true,
    idleTimeOut         : 480, // menit
    menuStatic          : AppStaticMenu.model,
    socketIO            : 'http://192.168.0.33:2222'
};

var v177 = {
    apiBackend          : '',

    dataMaster          : 'http://192.168.0.177:9292',
    dataMasterNew       : 'http://192.168.0.177:9292',

    resourceFile        : 'http://192.168.0.177:9797',
    report              : 'http://192.168.0.177:9797',
    smartReport         : 'http://192.168.0.177:6797',
    authLogin           : 'http://192.168.0.177:9797',

    dataHr1Mod1         : 'http://192.168.0.177:9393',
    dataHr1Mod2         : 'http://192.168.0.177:9393',
    dataHr2Mod1         : 'http://192.168.0.177:9393',
    dataHr2Mod2         : 'http://192.168.0.177:9393',

    dataHr2Mod3         : 'http://192.168.0.177:9696',

    dataBSC             : 'http://192.168.0.177:9898',

    dataBridging        : 'http://192.168.0.177:9193',

    resourceReport      : 'http://192.168.0.177:9797',
    page                : 1,
    rows                : 15,
    headerToken         : 'x-auth-token',
    menuDinamic         : true,
    idleTimeOut         : 480, // menit
    menuStatic          : AppStaticMenu.model,
    socketIO            : 'http://192.168.0.177:2222'
};

var v115 = {
    apiBackend          : '',

    dataMaster          : 'https://115.85.80.20/dataMaster',
    dataMasterNew       : 'https://115.85.80.20/dataMaster',

    resourceFile        : 'https://115.85.80.20/authInfo',
    report              : 'https://115.85.80.20/authInfo',
    smartReport         : 'https://115.85.80.20/smartReport',
    authLogin           : 'https://115.85.80.20/authInfo',

    dataHr1Mod1         : 'https://115.85.80.20/dataHr1Mod1',          
    dataHr1Mod2         : 'https://115.85.80.20/dataHr1Mod1',          
    dataHr2Mod1         : 'https://115.85.80.20/dataHr1Mod1',
    dataHr2Mod2         : 'https://115.85.80.20/dataHr1Mod1',

    dataHr2Mod3         : 'https://115.85.80.20/dataHr2Mod3',

    dataBSC             : 'https://115.85.80.20/dataBSC',   

    dataBridging        : 'https://115.85.80.20/dataBridging',

    resourceReport      : 'https://115.85.80.20/authInfo', 
    page                : 1,
    rows                : 15,
    headerToken         : 'x-auth-token',
    menuDinamic         : true,
    idleTimeOut         : 30, // menit
    menuStatic          : AppStaticMenu.model,
    socketIO            : 'https://115.85.80.20:2222'
}

var v161 = {
    apiBackend          : '',

    dataMaster          : 'http://103.93.161.29/dataMaster',
    dataMasterNew       : 'http://103.93.161.29/dataMaster',

    resourceFile        : 'http://103.93.161.29/authInfo',
    report              : 'http://103.93.161.29/authInfo',
    smartReport         : 'http://103.93.161.29/smartReport',
    authLogin           : 'http://103.93.161.29/authInfo',

    dataHr1Mod1         : 'http://103.93.161.29/dataHr1Mod1',          
    dataHr1Mod2         : 'http://103.93.161.29/dataHr1Mod1',          
    dataHr2Mod1         : 'http://103.93.161.29/dataHr1Mod1',
    dataHr2Mod2         : 'http://103.93.161.29/dataHr1Mod1',

    dataHr2Mod3         : 'http://103.93.161.29/dataHr2Mod3',

    dataBSC             : 'http://103.93.161.29/dataBSC',   

    dataBridging        : 'http://103.93.161.29/dataBridging',

    resourceReport      : 'http://103.93.161.29/authInfo', 
    page                : 1,
    rows                : 15,
    headerToken         : 'x-auth-token',
    menuDinamic         : true,
    idleTimeOut         : 480, // menit
    menuStatic          : AppStaticMenu.model,
    socketIO            : 'https://103.93.161.29:2222'
}




var vBdg2Jasmed = {
  apiBackend          : '',

  dataMaster          : 'http://bdg2.jasamedika.com:2304/dataMaster',
  dataMasterNew       : 'http://bdg2.jasamedika.com:2304/dataMaster',

  resourceFile        : 'http://bdg2.jasamedika.com:2304/authInfo',
  report              : 'http://bdg2.jasamedika.com:2304/authInfo',
  smartReport         : 'http://bdg2.jasamedika.com:2304/smartReport',
  authLogin           : 'http://bdg2.jasamedika.com:2304/authInfo',

  dataHr1Mod1         : 'http://bdg2.jasamedika.com:2304/dataHr1Mod1',          
  dataHr1Mod2         : 'http://bdg2.jasamedika.com:2304/dataHr1Mod1',          
  dataHr2Mod1         : 'http://bdg2.jasamedika.com:2304/dataHr1Mod1',
  dataHr2Mod2         : 'http://bdg2.jasamedika.com:2304/dataHr1Mod1',

  dataHr2Mod3         : 'http://bdg2.jasamedika.com:2304/dataHr2Mod3',

  dataBSC             : 'http://bdg2.jasamedika.com:2304/dataBSC',   

  dataBridging        : 'http://bdg2.jasamedika.com:2304/dataBridging',

  resourceReport      : 'http://bdg2.jasamedika.com:2304/authInfo', 
  page                : 1,
  rows                : 15,
  headerToken         : 'x-auth-token',
  menuDinamic         : true,
  idleTimeOut         : 240, // menit
  menuStatic          : AppStaticMenu.model,
  socketIO            : 'http://bdg2.jasamedika.com:2304'
}

var vJasmed = {
    apiBackend          : '',

    dataMaster          : 'https://hris.jasamedika.co.id/dataMaster',
    dataMasterNew       : 'https://hris.jasamedika.co.id/dataMaster',

    resourceFile        : 'https://hris.jasamedika.co.id/authInfo',
    report              : 'https://hris.jasamedika.co.id/authInfo',
    smartReport         : 'https://hris.jasamedika.co.id/smartReport',
    authLogin           : 'https://hris.jasamedika.co.id/authInfo',

    dataHr1Mod1         : 'https://hris.jasamedika.co.id/dataHr1Mod1',          
    dataHr1Mod2         : 'https://hris.jasamedika.co.id/dataHr1Mod1',          
    dataHr2Mod1         : 'https://hris.jasamedika.co.id/dataHr1Mod1',
    dataHr2Mod2         : 'https://hris.jasamedika.co.id/dataHr1Mod1',

    dataHr2Mod3         : 'https://hris.jasamedika.co.id/dataHr2Mod3',

    dataBSC             : 'https://hris.jasamedika.co.id/dataBSC',   

    dataBridging        : 'https://hris.jasamedika.co.id/dataBridging',

    resourceReport      : 'https://hris.jasamedika.co.id/authInfo', 
    page                : 1,
    rows                : 15,
    headerToken         : 'x-auth-token',
    menuDinamic         : true,
    idleTimeOut         : 240, // menit
    menuStatic          : AppStaticMenu.model,
    socketIO            : 'https://hris.jasamedika.co.id'
}

var vBdg2grandmed  = {
    apiBackend          : '',

    dataMaster          : 'https://hris.rsgrandmed.com/dataMaster',
    dataMasterNew       : 'https://hris.rsgrandmed.com/dataMaster',

    resourceFile        : 'https://hris.rsgrandmed.com/authInfo',
    report              : 'https://hris.rsgrandmed.com/authInfo',
    smartReport         : 'https://hris.rsgrandmed.com/smartReport',
    authLogin           : 'https://hris.rsgrandmed.com/authInfo',

    dataHr1Mod1         : 'https://hris.rsgrandmed.com/dataHr1Mod1',          
    dataHr1Mod2         : 'https://hris.rsgrandmed.com/dataHr1Mod1',          
    dataHr2Mod1         : 'https://hris.rsgrandmed.com/dataHr1Mod1',
    dataHr2Mod2         : 'https://hris.rsgrandmed.com/dataHr1Mod1',

    dataHr2Mod3         : 'https://hris.rsgrandmed.com/dataHr2Mod3',

    dataBSC             : 'https://hris.rsgrandmed.com/dataBSC',   

    dataBridging        : 'https://hris.rsgrandmed.com/dataBridging',

    resourceReport      : 'https://hris.rsgrandmed.com/authInfo', 
    page                : 1,
    rows                : 15,
    headerToken         : 'x-auth-token',
    menuDinamic         : true,
    idleTimeOut         : 240, // menit
    menuStatic          : AppStaticMenu.model,
    socketIO            : 'https://hris.rsgrandmed.com'
}

var vBottis = {
    apiBackend          : '',

    dataMaster          : 'https://hris.bottis.co.id/dataMaster',
    dataMasterNew       : 'https://hris.bottis.co.id/dataMaster',

    resourceFile        : 'https://hris.bottis.co.id/authInfo',
    report              : 'https://hris.bottis.co.id/authInfo',
    smartReport         : 'https://hris.bottis.co.id/smartReport',
    authLogin           : 'https://hris.bottis.co.id/authInfo',

    dataHr1Mod1         : 'https://hris.bottis.co.id/dataHr1Mod1',          
    dataHr1Mod2         : 'https://hris.bottis.co.id/dataHr1Mod1',          
    dataHr2Mod1         : 'https://hris.bottis.co.id/dataHr1Mod1',
    dataHr2Mod2         : 'https://hris.bottis.co.id/dataHr1Mod1',

    dataHr2Mod3         : 'https://hris.bottis.co.id/dataHr2Mod3',

    dataBSC             : 'https://hris.bottis.co.id/dataBSC',   

    dataBridging        : 'https://hris.bottis.co.id/dataBridging',

    resourceReport      : 'https://hris.bottis.co.id/authInfo', 
    page                : 1,
    rows                : 15,
    headerToken         : 'x-auth-token',
    menuDinamic         : true,
    idleTimeOut         : 240, // menit
    menuStatic          : AppStaticMenu.model,
    socketIO            : 'https://hris.bottis.co.id'
}

var vBottisId = {
  apiBackend          : '',

  dataMaster          : 'https://hris.bottis.id/dataMaster',
  dataMasterNew       : 'https://hris.bottis.id/dataMaster',

  resourceFile        : 'https://hris.bottis.id/authInfo',
  report              : 'https://hris.bottis.id/authInfo',
  smartReport         : 'https://hris.bottis.id/smartReport',
  authLogin           : 'https://hris.bottis.id/authInfo',

  dataHr1Mod1         : 'https://hris.bottis.id/dataHr1Mod1',          
  dataHr1Mod2         : 'https://hris.bottis.id/dataHr1Mod1',          
  dataHr2Mod1         : 'https://hris.bottis.id/dataHr1Mod1',
  dataHr2Mod2         : 'https://hris.bottis.id/dataHr1Mod1',

  dataHr2Mod3         : 'https://hris.bottis.id/dataHr2Mod3',

  dataBSC             : 'https://hris.bottis.id/dataBSC',   

  dataBridging        : 'https://hris.bottis.id/dataBridging',

  resourceReport      : 'https://hris.bottis.id/authInfo', 
  page                : 1,
  rows                : 15,
  headerToken         : 'x-auth-token',
  menuDinamic         : true,
  idleTimeOut         : 240, // menit
  menuStatic          : AppStaticMenu.model,
  socketIO            : 'https://hris.bottis.id'
}


var flagsF = function () {
    var flags = [];
    
    flags["id"] = "id";
    flags["us"] = "us";
    
    return flags;
}

export class LangToFlag {
    static flags = flagsF();
}

////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////